import axios from "axios"
export class ServicoMarca {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + "/api/Marca/"
  }
  buscarMarcas() {
    return axios({
      url: this.urlServico + `GetAll`,
      method: "GET"
    })
  }
  salvarMarca(id, nome, status) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    let statusString = status ? "SIM" : "NAO"
    return axios.post(this.urlServico + "Salvar", { id: id, nome: nome, ativo: statusString }, config).catch(error => {
      alert("Erro ao salvar uma nova marca " + error)
      return Promise.reject(error)
    })
  }
  alterarMarca(id, nome, status) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    let statusString = status ? "SIM" : "NAO"
    return axios.post(this.urlServico + "Alterar", { id: id, nome: nome, ativo: statusString }, config).catch(error => {
      alert("Erro ao salvar uma nova marca " + error)
      return Promise.reject(error)
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `GetById?id=${id}`,
      method: "GET"
    })
  }
}

<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
      label="Marca"
      name="nome"
      placeholder="Marca"
      v-model="nome"
      :counter="30"
      :rules="nameRules"
      required
    ></v-text-field>
    <v-switch v-model="status" :label="status ? 'Ativo' : 'Inativo'"></v-switch>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <router-link :to="{ name: 'Marca' }" class="nounderline">
        <v-btn outline>Voltar</v-btn>
      </router-link>
    </div>
  </v-form>
</template>
<script>
import { ServicoMarca } from "../../servicos/servicoMarca"
const servicoMarca = new ServicoMarca()
export default {
  data: () => ({
    id: null,
    nome: "",
    status: true,
    nameRules: [
      v => !!v || "Nome é obrigatório",
      v => v.length <= 30 || "Nome não deve possuir mais que 30 caracteres",
      v => v.length >= 3 || "Nome não deve possuir menos que 3 caracteres"
    ],
    valid: true
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (this.id != null) {
          servicoMarca.alterarMarca(this.id, this.nome, this.status).then(
            res => {
              if (res.status === 200) {
                this.$router.push({ name: "Marca" })
              }
            },
      // eslint-disable-next-line
            err => console.log(err)
          )
        } else {
          servicoMarca.salvarMarca(this.id, this.nome, this.status).then(
            res => {
              if (res.status === 200) {
                this.$router.push({ name: "Marca" })
              }
            },
      // eslint-disable-next-line
            err => console.log(err)
          )
        }
      }
    },
    clear() {
      this.nome = ""
      this.$refs.form.resetValidation()
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoMarca.buscarPorId(this.id).then(res => {
        this.nome = res.data.nome
        this.status = res.data.ativo == "SIM" ? true : false
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
